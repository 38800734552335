@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./custom/fonts.css";

@layer base {
    
    body {
        font-family: "montserrat", sans-serif;
        color: white;
    }
    h1, h2, h3, h4, h5 {
        font-family: 'Playfair Display';
    }

    .playfair {
        font-family: 'Playfair Display';
    }

}

@layer components {

    #header {
        position: fixed;
        top: -150px;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #171424;
        align-items: center;
        z-index: 99999;
    }

    #header.scrolled {
        display: flex;
        top: 0;
    }
    #header.top {
        display: flex;
        position: relative;
        top: 0;
    }

    #header ul li {
        display: inline-block;
        margin-left: 10px;
        text-transform: uppercase;
        font-size: 14px;
    }

    #header ul li a {
        border-bottom: 1px solid #171424;
        padding: 5px;
    }

    #header ul li a:hover,
    #header ul li a.active {
        color: #F6A83A;
        border-color: #F6A83A;
    }

    .btn {
        color: black;
        padding: 0 30px;
        height: 50px;
        line-height: 50px;
        display: inline-block;
    }

    /*
    .gold-link {
        background-color: #F6A83A;
        position: relative;
        padding: 45px 30px;
        display: inline-block;
        min-height: 225px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #F6A83A;
        background-image: url("https://www.prideoftamesideawards.co.uk/uploads/page-images/gold-tex.png");
        background-size: cover;
    }

    .gold-link .hoverContent {
        display: none;
    }

    .gold-link:hover {
        background-color: black;
        color: #F6A83A;
        background-image: none;
    }

    .gold-link:hover .hoverContent {
        display: block;
    }

    .gold-link:hover .content {
        display: none;
    }

    .gold-link:before {
        content: '';
        position: absolute;
        left: -10px;
        top: -10px;
        right: 10px;
        bottom: 10px;
        border: 1px solid #F6A83A;
        background-color: transparent;
        z-index: -1;
    }

    */

    .form-input {
        @apply bg-gray-50 border leading-8 text-base border-gray-300 text-gray-900 rounded-none block w-full p-2.5;
    }

    .form-label {
        @apply block mb-2 text-sm font-light text-gold uppercase;
    }

    .form-button {
        @apply bg-gold py-3 px-8 text-brand rounded-sm font-bold hover:bg-white transition-all;
    }

    .freeform-form-errors,
    .freeform-errors {
        @apply hidden;
    }

}

.freeform-has-errors {
    @apply border-red-600;
}